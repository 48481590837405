<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-12 category-filters">

        <center>
          <h3>Qualcosa è andato storto con la tua richiesta di pagamento, riprova più tardi</h3>
          <br><br>
          <router-link class="button" to="/cart">Ripeti il pagamento</router-link>
        </center>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import queryString from 'query-string'

export default {
  name: 'Failed',
  data: function () {
    return { }
  },

  async created () {
    let queryData = queryString.parse(location.search)

    if (queryData.id) {
      await axios.get(this.$config.backendUrl + 'index.php?action=set_order_status&status=3&id=' + queryData.id)
    }
  },

  methods: {
    
  }
}
</script>

<style scoped>
.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
}

.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}

.category-content p {
  margin: 0 0 25px 0;
  max-width: 66.66666%;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-weight: 13px;
  margin-bottom: 12px;
}
</style>

